import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import axios from "axios";

const getDelta = (a, b) => {
  const intA = parseInt(a);
  const intB = parseInt(b);

  const delta = (intB - intA) / 1000;
  return delta;
};

const AWSManagement = ({
  setAudioFiles,
  setVideoFiles,
  setFinalAudio,
  hasAudio,
}) => {
  const [folders, setFolders] = useState([]);
  const [contents, setContents] = useState([]);
  const [callStartTimes, setCallStartTimes] = useState([]);

  const generateAudio = async (cid) => {
    console.log("axios call");
    const res = await axios.post(
      `https://auto-merge-process.iconnectadvisor.com/recordProcess`,
      {
        callerId: cid,
        env: process.env.ENV_NAME || "prod",
      }
    );

    // Refresh the page to get the final_audio.webm
    window.location.reload();

    console.log("Waiting for response will be back soon");
  };

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: "AKIATFRGCPN5RPZ6HOM2", // replace with your access key
      secretAccessKey: "iA0/4hJp/Ukl9WKEG9r6vNW04UhCnSeSUu/oQyVs", // replace with your secret key
      region: "us-east-2", // replace with your AWS region
    });

    const bucketName = "iconnect-final-recordings"; // replace with your S3 bucket name

    // Get cid parameter from URL
    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get("cid");

    const prefix = cid || "0ca712af-a5b3-4681-b318-a9f0c1210e12"; // replace with the path of the folder you want to list

    const params = {
      Bucket: bucketName,
      Delimiter: "iconnect-final-files",
      Prefix: prefix,
    };

    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.error(err);
      } else {
        const folders = [];
        const audioFolders = [];
        const contents = data.Contents;
        let startTimeStamp = null;
        let endTimeStamp = null;
        setContents(contents);
        contents.forEach((content, i) => {
          const folder = content.Key.split("/")[1];
          if (
            !audioFolders.includes(folder) &&
            folder !== "output" &&
            folder !== "merge_process_started" &&
            folder !== "final_audio" &&
            folder.includes("audio")
          ) {
            audioFolders.push(folder);
            const folderContents = contents.filter((content) =>
              content.Key.includes(folder)
            );
            console.log("folderContents", folderContents);
            if (folderContents.length > 0) {
              folders.push(folder);
              //   Sort contents by Key (filename)
              const sortedFolderContents = folderContents.sort((a, b) => {
                if (a.Key > b.Key) {
                  return 1;
                } else {
                  return -1;
                }
              });
              const URLS = sortedFolderContents.map((content) => {
                return `https://iconnect-final-recordings.s3.us-east-2.amazonaws.com/${content.Key}`;
              });

              console.log("sortedFolderContents", sortedFolderContents);

              if (!startTimeStamp) {
                startTimeStamp =
                  sortedFolderContents[0].Key.split("/")[2].split(".")[0];
              }

              if (!endTimeStamp && i > 0) {
                endTimeStamp =
                  sortedFolderContents[0]?.Key.split("/")[2].split(".")[0];
              }

              setAudioFiles((audioFiles) => [
                ...audioFiles,
                {
                  [`audio${audioFolders.length - 1}`]: URLS,
                },
              ]);
            }
          }
          if (
            !folders.includes(folder) &&
            folder !== "output" &&
            folder !== "merge_process_started" &&
            folder !== "final_audio" &&
            folder.includes("video")
          ) {
            const folderContents = contents.filter((content) =>
              content.Key.includes(folder)
            );
            if (folderContents.length > 0) {
              folders.push(folder);
              //   Sort contents by Key (filename)
              const sortedFolderContents = folderContents.sort((a, b) => {
                if (a.Key > b.Key) {
                  return 1;
                } else {
                  return -1;
                }
              });
              //   console.log("folder", folder, ":", sortedFolderContents);
              const videoStartTimestamp =
                sortedFolderContents[0].Key.split("/")[2].split(".")[0];

              const delay = getDelta(startTimeStamp, videoStartTimestamp);
              const videoDelay = delay;
              const delayPerMinute = 1;
              const loadDelay = parseInt(delay / 15) * delayPerMinute;

              console.log("delay", delay);
              console.log("startTimeStamp", startTimeStamp);
              console.log("loadDelay", loadDelay);

              const fDelay = videoDelay;
              // videoDelay > 50 ? videoDelay - loadDelay : videoDelay;

              console.log("fDelay", fDelay);

              setVideoFiles((videoFiles) => [
                ...videoFiles,
                {
                  id: folder,
                  name: folder,
                  delay: fDelay * 1000,
                  chunks: sortedFolderContents.map((content) => {
                    return content.Key;
                  }),
                },
              ]);
            }
          }
        });
        if (audioFolders.length > 0) {
          // Sort audio folders by folder name
          const sortedAudioFolders = audioFolders.sort((a, b) => {
            if (a > b) {
              return 1;
            } else {
              return -1;
            }
          });
          // Remove "audio__" from folder name
          const callStartTimes = sortedAudioFolders.map((folder) =>
            folder.replace("audio__", "")
          );
          setCallStartTimes(callStartTimes);
        }

        setFolders(folders);

        if (contents.length > 0) {
          const tempAudio = contents.filter((content) =>
            content.Key.includes("final_audio.webm")
          );
          console.log("tempAudio", tempAudio);
          if (tempAudio.length > 0) {
            setFinalAudio(
              `https://iconnect-final-recordings.s3.us-east-2.amazonaws.com/${tempAudio[0].Key}`
            );
          }
        }
      }
    });
  }, []);

  return (
    <div>
      {/* <h2>Folder List</h2>
      <ul>
        {folders.map((folder, index) => (
          <li key={index}>{folder}</li>
        ))}
      </ul> */}
    </div>
  );
};

export default AWSManagement;
