import axios from "axios";

export const saveRecordingData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get("cid");
    const savedURLAndTime = localStorage.getItem(cid);
    console.log("savedURLAndTime", savedURLAndTime);
    if (savedURLAndTime) {
        const parsed = JSON.parse(savedURLAndTime);
        console.log("parsed", parsed);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v3/save-recording-forward-reverse-values`, {
            callerId: cid,
            recording_values: parsed,
        })

    }

}

export const generateRecordingData = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get("cid");
    const savedURLAndTime = localStorage.getItem(cid);
    const res = axios.post(`https://pre-production-api.iconnectadvisor.com/api/v3/generate-recording-forward-reverse-values`, {
        callerId: cid,
    })
}

export const getRecordingData = async (cid) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v3/get-recording-forward-reverse-values/${cid}`);
        if (res.data) {
            const { recording_values } = res?.data;
            localStorage.setItem(cid, JSON.stringify(recording_values));
        }
        else {
            // remove the local storage
            localStorage.removeItem(cid);
            return null;
        }
        return res?.data;
    }
    catch (e) {
        console.log("Error in getRecordingData", e);
        return null;
    }
}