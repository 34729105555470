import React, { useRef, useEffect, useState } from "react";
import AudioFile from "./final_audio.webm";

const AudioPlayer = ({
  audio1,
  audio2,
  DELAY,
  setPlayStarted,
  playStarted,
  isPaused,
  time,
  setIsPaused,
  forwardTo,
  setForwardTo,
  setTime,
  startTimestamp,
  finalAudio,
  playingForTheFirstTime,
  hasAudio,
}) => {
  const playerOne = useRef(null);
  const playerTwo = useRef(null);
  const playerThree = useRef(null);

  useEffect(() => {
    if (!hasAudio) {
      return;
    }
    if (playStarted) {
      if (isPaused) {
        playerOne.current.pause();
        playerTwo.current.pause();
        playerThree.current.pause();
      } else {
        playerOne.current.pause();
        playerTwo.current.pause();
        playerThree.current.play();
      }
    }
  }, [isPaused]);

  useEffect(() => {
    if (finalAudio) {
      playerThree.current.src = finalAudio;
      const audio = new Audio();
      audio.src = finalAudio;
      audio.preload = "auto";
    }
  }, [finalAudio]);

  useEffect(() => {
    // If time is multiple of 60 seconds, save the URL and time

    const temp = time;

    const frTimeGap = parseInt(process.env.REACT_APP_FORWARD_REVERSE_TIME_GAP);
    if (time % frTimeGap === 0) {
      console.log("time", time);
      saveURLAndTimeEvery60Seconds(temp);
    }
  }, [time]);

  const handleAudioEnd = (player, currentArray) => {
    // Find the index of the current track in the array
    const currentIndex = currentArray.indexOf(player.src);

    // Check if there is a next track in the array
    if (currentIndex < currentArray.length - 1) {
      // setIsPaused(false);
      // Play the next track
      const nextIndex = currentIndex + 1;
      player.src = currentArray[nextIndex];
      player.play();
      if (nextIndex < currentArray.length - 1) {
        preloadNextAudio(nextIndex + 1, currentArray);
      }
      // setIsPaused(true);
    }
  };

  const preloadNextAudio = (nextIndex, audioArray) => {
    const audio = new Audio();
    audio.src = audioArray[nextIndex];
    audio.preload = "auto";
  };

  const handleButtonClick = () => {
    // Start playing the first audio from array 1
    // if (playerOne.current) {
    //   playerOne.current.src = audio1[0];
    //   playerOne.current.play();
    //   setPlayStarted(true);
    // }
    // if (playerTwo.current) {
    //   playerTwo.current.src = audio2[0];
    //   playerTwo.current.play();
    // }
    if (playerThree.current) {
      playerThree.current.play();
      setPlayStarted(true);
    }
  };

  const handleForwardToTimeStamp = (forwardTo) => {
    // Find the index of the audio file that corresponds to the timestamp

    const AllTimestamps1 = audio1.map((url) => {
      const match = url.match(/\/(\d+)\.webm$/);
      return match ? match[1] : null;
    });

    const AllTimestamps1res = findPreviousWithNext(AllTimestamps1, forwardTo);

    console.log("Audio 1", audio1);
    console.log("AllTimestamps1res", AllTimestamps1res);

    handleAudioURLChangeWithForwarInSeconds(
      playerOne.current,
      audio1[AllTimestamps1res.index],
      AllTimestamps1res.gap
    );

    const AllTimestamps2 = audio2.map((url) => {
      const match = url.match(/\/(\d+)\.webm$/);
      return match ? match[1] : null;
    });
    const audio2ForwardTo = forwardTo + 29000;
    const AllTimestamps2res = findPreviousWithNext(
      AllTimestamps2,
      audio2ForwardTo
    );

    console.log("Audio 2", audio2);
    console.log("AllTimestamps2res", AllTimestamps2res);

    handleAudioURLChangeWithForwarInSeconds(
      playerTwo.current,
      audio2[AllTimestamps2res.index],
      AllTimestamps2res.gap
    );
  };

  function findPreviousWithNext(array, targetTimestamp) {
    const targetNumber = parseInt(targetTimestamp, 10);

    let closestTimestamp = null;
    let closestDifference = Infinity;

    for (let i = 0; i < array.length; i++) {
      const currentNumber = parseInt(array[i], 10);
      const difference = currentNumber - targetNumber;
      // Dont allow negative differences
      if (difference < 0) {
        continue;
      }
      const gap = difference / 1000;

      if (difference <= closestDifference && i > 0 && i < array.length - 1) {
        closestDifference = difference;
        closestTimestamp = {
          index: i,
          previousTimestamp: parseInt(array[i], 10),
          nextTimestamp: parseInt(array[i + 1], 10),
          targetNumber,
          gap: gap,
        };
      }
    }
    console.log("closestTimestamp", closestTimestamp);
    return closestTimestamp;
  }

  const handleAudioURLChangeWithForwarInSeconds = (player, URL, tm) => {
    player.src = URL;
    player.currentTime += tm;
  };

  const saveURLAndTimeEvery60Seconds = (time) => {
    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get("cid");
    const savedURLAndTime = localStorage.getItem(cid);

    //  Check if the time is already saved in local storage
    if (savedURLAndTime) {
      const parsedSavedURLAndTime = JSON.parse(savedURLAndTime);
      const savedTime = Object.keys(parsedSavedURLAndTime);
      const savedTimeIndex = savedTime.indexOf(time.toString());
      if (savedTimeIndex >= 0) {
        return;
      }
    }

    const url1 = playerOne.current.src;
    const url2 = playerTwo.current.src;
    const url3 = playerThree.current.src;

    const currentTime1 = playerOne.current.currentTime;
    const currentTime2 = playerTwo.current.currentTime;
    const currentTime3 = playerThree.current.currentTime;

    const audio1Index = audio1?.indexOf(url1);
    const audio2Index = audio2?.indexOf(url2);

    // Save the URL and time every 60 seconds in local storage
    if (audio1Index >= 0) {
      console.log("audio1Index", url1, audio1Index, currentTime1);
    }
    if (audio2Index >= 0) {
      console.log("audio2Index", url2, audio2Index, currentTime2);
    }

    if (savedURLAndTime) {
      const parsedSavedURLAndTime = JSON.parse(savedURLAndTime);
      const newSavedURLAndTime = {
        ...parsedSavedURLAndTime,
        [time]: {
          url1,
          url2,
          currentTime1,
          currentTime2,
          audio1Index,
          audio2Index,
          currentTime3,
        },
      };
      localStorage.setItem(cid, JSON.stringify(newSavedURLAndTime));
    } else {
      const newSavedURLAndTime = {
        [time]: {
          url1,
          url2,
          currentTime1,
          currentTime2,
          audio1Index,
          audio2Index,
          currentTime3,
        },
      };
      localStorage.setItem(cid, JSON.stringify(newSavedURLAndTime));
    }
  };

  const forwardToSavedTime = () => {
    const temp = time;
    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get("cid");

    const savedURLAndTime = localStorage.getItem(cid);
    if (savedURLAndTime) {
      setIsPaused(true);
      const parsedSavedURLAndTime = JSON.parse(savedURLAndTime);
      const vdoForwardButton = document.getElementById("vdo-frwd");
      // Check if the vdo-frwd button is available
      if (vdoForwardButton) {
        console.log(vdoForwardButton);
        vdoForwardButton.click();
      }

      const frTimeGap = parseInt(
        process.env.REACT_APP_FORWARD_REVERSE_TIME_GAP
      );

      const tempDiv = Math.floor(temp / frTimeGap);
      const closestSavedTime = (tempDiv + 1) * frTimeGap;

      const closestSavedTimeObject = parsedSavedURLAndTime[closestSavedTime];
      if (!closestSavedTimeObject) {
        return;
      }
      setTime(+closestSavedTime);
      playerThree.current.currentTime = closestSavedTimeObject.currentTime3;

      setTimeout(() => {
        setIsPaused(false);
      }, 500);
    }
  };

  const reverseToSavedTime = () => {
    const temp = time;
    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get("cid");

    const frTimeGap = parseInt(process.env.REACT_APP_FORWARD_REVERSE_TIME_GAP);
    const savedURLAndTime = localStorage.getItem(cid);
    if (savedURLAndTime) {
      setIsPaused(true);
      const parsedSavedURLAndTime = JSON.parse(savedURLAndTime);
      console.log(document.getElementById("vdo-bck"));
      document.getElementById("vdo-bck").click();
      let closestSavedTime;
      const tempDiv = Math.floor(temp / frTimeGap);
      const reminder = temp % frTimeGap;
      if (reminder < 20 && tempDiv > 1) {
        closestSavedTime = (tempDiv - 1) * frTimeGap;
      } else {
        closestSavedTime = tempDiv * frTimeGap;
      }

      const closestSavedTimeObject = parsedSavedURLAndTime[closestSavedTime];
      console.log(
        "closestSavedTimeObject",
        closestSavedTimeObject,
        closestSavedTime
      );
      if (!closestSavedTimeObject) {
        return;
      }
      setTime(+closestSavedTime);
      playerThree.current.currentTime = closestSavedTimeObject.currentTime3;

      setTimeout(() => {
        setIsPaused(false);
      }, 500);
    }
  };

  return (
    <>
      <div className="audio-player">
        <button
          className="start-button"
          id="start-playback-button"
          onClick={handleButtonClick}
          style={{
            display: playStarted ? "none" : "block",
          }}
        >
          {" "}
          {playStarted ? "Playing..." : "Start Playback"}
        </button>
        <div
          style={{
            opacity: 0,
          }}
        >
          <audio id="audio_player" ref={playerThree} controls>
            <source src="" type="audio/webm" />
          </audio>
          <audio
            muted
            ref={playerOne}
            controls
            onEnded={() => handleAudioEnd(playerOne.current, audio1)}
          >
            <source src="" type="audio/webm" />
          </audio>
          <audio
            ref={playerTwo}
            controls
            onEnded={() => handleAudioEnd(playerTwo.current, audio2)}
            muted
          >
            <source src="" type="audio/webm" />
          </audio>
        </div>
        {/* <div>{playerOne?.current?.src}</div>
        <div>{playerTwo?.current?.src}</div> */}
      </div>

      {time > 2 && !playingForTheFirstTime ? (
        <div>
          <button
            style={{
              opacity: 0,
            }}
            id="audio-bck"
            onClick={reverseToSavedTime}
          >
            Reverse
          </button>
          <button
            style={{
              opacity: 0,
            }}
            id="audio-frwd"
            onClick={forwardToSavedTime}
          >
            Forward
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AudioPlayer;
