import React, { useEffect, useRef, useState } from 'react'
import Logo from "./ic.png";
const showLOG = (msg, val) => {
    if (true) {
        console.log(msg);
        console.log(val);
    }
};
const NoAudioPlayer = ({
    videoFiles,
    time,
    isPaused,
    playStarted,
    forwardTo,
    setForwardTo,
    setPlayStarted
}) => {
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showPlayer, setShowPlayer] = useState(false);
    const [videoEndedTimer, setVideoEndedTimer] = useState(0);
    const [skipTo, setSkipTo] = useState(0);
    const videoRef = useRef(null);

    useEffect(() => {
        console.log("forwardTo", forwardTo);
        if (forwardTo > 0) {
            showLOG(`video forwardTo ${forwardTo}`);
            handleForwardToTimeStamp(forwardTo);
            setForwardTo(0);
        }
    }, [forwardTo]);

    useEffect(() => {
        // If time is multiple of 60 seconds, save the URL and time

        const temp = time;
        const frTimeGap = parseInt(process.env.REACT_APP_FORWARD_REVERSE_TIME_GAP);

        if (time % frTimeGap === 0 && time > 0) {
            console.log("time", time);
            saveURLAndTimeEvery60Seconds(temp);
        }
    }, [time]);

    useEffect(() => {
        // Check if not playing using videoRef then return
        if (!videoRef.current) return;

        if (playStarted && time > 5) {
            if (isPaused) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
                setIsPlaying(true);
                setShowPlayer(true);
            }
        }
    }, [isPaused]);

    useEffect(() => {
        if (playStarted) {
            setShowPlayer(true);
        }
    }, [playStarted]);

    useEffect(() => {
        if (videoEndedTimer >= 10 && !isPaused) {
            handleVideoEnded();
        }
    }, [videoEndedTimer]);

    useEffect(() => {
        if (videoRef.current) {
            if (isPaused) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
        }
    }, [showPlayer]);

    useEffect(() => {
        if (videoRef.current && skipTo > 0) {
            console.log("skipTo", skipTo);
            setTimeout(() => {
                videoRef.current.currentTime = skipTo;
                setSkipTo(0);
            }, 500);
        }
    }, [skipTo]);

    const saveURLAndTimeEvery60Seconds = (time) => {
        const urlParams = new URLSearchParams(window.location.search);
        const cid = urlParams.get("cid");

        if (!videoRef.current) {
            return;
        }

        const tempCurrentVideoIndex = currentVideoIndex;
        const tempCurrentChunkIndex = currentChunkIndex;
        const currentVideoTime = videoRef.current.currentTime;

        setTimeout(() => {
            const savedURLAndTime = localStorage.getItem(cid);
            if (savedURLAndTime) {
                const parsedSavedURLAndTime = JSON.parse(savedURLAndTime);
                const newSavedURLAndTime = {
                    ...parsedSavedURLAndTime,
                    [time]: {
                        ...parsedSavedURLAndTime[time],
                        videoFolderIndex: tempCurrentVideoIndex,
                        videoChunkIndex: tempCurrentChunkIndex,
                        videoTime: currentVideoTime,
                    },
                };
                localStorage.setItem(cid, JSON.stringify(newSavedURLAndTime));
            } else {
                const newSavedURLAndTime = {
                    [time]: {
                        videoFolderIndex: tempCurrentVideoIndex,
                        videoChunkIndex: tempCurrentChunkIndex,
                        videoTime: currentVideoTime,
                    },
                };
                localStorage.setItem(cid, JSON.stringify(newSavedURLAndTime));
            }
        }, 1000);
    };

    const handleForwardToTimeStamp = (forwardTo) => {
        const res = findClosestWithPreviousAndNext(videoFiles, forwardTo);
        showLOG("videoFiles", videoFiles);
        showLOG("video res", res);
        if (res && res.closestTimestamp) {
            setShowPlayer(true);

            setCurrentVideoIndex(res.closestObjectIndex);
            setCurrentChunkIndex(res.closestChunkIndex);
            setIsPlaying(false);
            setVideoEndedTimer(0);
            setSkipTo(res.closestDifference);
        } else {
            setShowPlayer(false);
            setIsPlaying(false);
            setVideoEndedTimer(0);
        }
    };

    function findClosestWithPreviousAndNext(objectsArray, targetTimestamp) {
        const targetNumber = parseInt(targetTimestamp, 10);

        let closestTimestamp = null;
        let closestObjectIndex = null;
        let closestChunkIndex = null;
        let closestDifference = Infinity;

        for (let i = 0; i < objectsArray.length; i++) {
            const chunks = objectsArray[i].chunks;

            // Check if the targetTimestamp is greater than the first chunk's timestamp and less than the last chunk's timestamp
            const firstChunkTimestamp = parseInt(
                chunks[0].match(/\/(\d+)\.webm$/)[1],
                10
            );
            const lastChunkTimestamp = parseInt(
                chunks[chunks.length - 1].match(/\/(\d+)\.webm$/)[1],
                10
            );
            if (targetNumber < firstChunkTimestamp) {
                continue;
            }
            if (targetNumber > lastChunkTimestamp) {
                continue;
            }

            for (let j = 0; j < chunks.length; j++) {
                const currentTimestamp = parseInt(
                    chunks[j].match(/\/(\d+)\.webm$/)[1],
                    10
                );
                const difference = currentTimestamp - targetNumber;
                if (difference < 0) {
                    continue;
                }

                if (difference <= closestDifference && j > 0 && j < chunks.length - 1) {
                    closestDifference = difference;
                    closestTimestamp = currentTimestamp;
                    closestObjectIndex = i;
                    closestChunkIndex = j;
                }
            }
        }

        return {
            closestTimestamp,
            closestObjectIndex,
            closestChunkIndex,
        };
    }

    const handleVideoEnded = () => {
        const currentVideo = videoFiles[currentVideoIndex];
        setVideoEndedTimer(0);
        // Check if there are more chunks in the current video
        if (currentChunkIndex < currentVideo.chunks.length - 1) {
            // Increase the currentChunkIndex to play the next chunk
            setCurrentChunkIndex((prevIndex) => prevIndex + 1);
        } else {
            // All chunks have been played, move to the next video
            console.log("All chunks have been played, move to the next video");
            setCurrentVideoIndex((prevIndex) => prevIndex + 1);
            setCurrentChunkIndex(0);
            setIsPlaying(false);
            setShowPlayer(false);
            setVideoEndedTimer(0);
        }
    };

    const forwardToSavedTime = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const cid = urlParams.get("cid");
        const temp = time;
        const savedURLAndTime = localStorage.getItem(cid);

        // videoFolderIndex: tempCurrentVideoIndex,
        //         videoChunkIndex: tempCurrentChunkIndex,
        //         videoTime: currentVideoTime,

        if (savedURLAndTime) {
            const parsedSavedURLAndTime = JSON.parse(savedURLAndTime);
            const frTimeGap = parseInt(
                process.env.REACT_APP_FORWARD_REVERSE_TIME_GAP
            );

            const tempDiv = Math.floor(temp / frTimeGap);
            const closestSavedTime = (tempDiv + 1) * frTimeGap;
            const closestSavedTimeObject = parsedSavedURLAndTime[closestSavedTime];
            console.log("videoRef.current", closestSavedTimeObject);
            if (closestSavedTimeObject?.videoFolderIndex >= 0) {
                setShowPlayer(true);
                setIsPlaying(true);
                setCurrentVideoIndex(closestSavedTimeObject.videoFolderIndex);
                setCurrentChunkIndex(closestSavedTimeObject.videoChunkIndex);
                setSkipTo(closestSavedTimeObject.videoTime);
            } else {
                setIsPlaying(false);
                setShowPlayer(false);
            }
        }
    };

    const reverseToSavedTime = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const cid = urlParams.get("cid");
        const temp = time;
        const savedURLAndTime = localStorage.getItem(cid);

        // videoFolderIndex: tempCurrentVideoIndex,
        //         videoChunkIndex: tempCurrentChunkIndex,
        //         videoTime: currentVideoTime,

        if (savedURLAndTime) {
            const frTimeGap = parseInt(
                process.env.REACT_APP_FORWARD_REVERSE_TIME_GAP
            );
            const parsedSavedURLAndTime = JSON.parse(savedURLAndTime);
            let closestSavedTime;
            const tempDiv = Math.floor(temp / frTimeGap);
            const reminder = temp % frTimeGap;
            if (reminder < 20 && tempDiv > 1) {
                closestSavedTime = (tempDiv - 1) * frTimeGap;
            } else {
                closestSavedTime = tempDiv * frTimeGap;
            }
            const closestSavedTimeObject = parsedSavedURLAndTime[closestSavedTime];
            console.log("videoRef.current", closestSavedTimeObject);
            if (closestSavedTimeObject?.videoFolderIndex >= 0) {
                setShowPlayer(true);
                setIsPlaying(true);
                setCurrentVideoIndex(closestSavedTimeObject.videoFolderIndex);
                setCurrentChunkIndex(closestSavedTimeObject.videoChunkIndex);
                setSkipTo(closestSavedTimeObject.videoTime);
            } else {
                setIsPlaying(false);
                setShowPlayer(false);
            }
        }
    };


    if (currentVideoIndex < videoFiles.length) {
        const currentVideo = videoFiles[currentVideoIndex];
        const currentChunk = currentVideo.chunks[currentChunkIndex];

        return (
            <>
                {showPlayer ? (
                    <div className="video-container">
                        {currentChunkIndex <
                            videoFiles[currentVideoIndex].chunks.length - 1 && (
                                <video
                                    src={`https://iconnect-final-recordings.s3.us-east-2.amazonaws.com/${videoFiles[currentVideoIndex].chunks[currentChunkIndex + 1]
                                        }`}
                                    preload="auto"
                                    style={{ display: "none" }}
                                />
                            )}
                        <video
                            ref={videoRef}
                            src={`https://iconnect-final-recordings.s3.us-east-2.amazonaws.com/${currentChunk}`}
                            onEnded={handleVideoEnded}
                            muted
                            autoPlay
                        />
                    </div>
                ) : (
                    <div className="logo-container">
                        <img src={Logo} alt="logo" />
                    </div>
                )}
                <button id="vdo-frwd" onClick={forwardToSavedTime}></button>
                <button id="vdo-bck" onClick={reverseToSavedTime}></button>
                <button onClick={() => setPlayStarted(true)}>play</button>
            </>
        );
    } else {
        return (
            <div className="logo-container">
                <img src={Logo} alt="logo" />
            </div>
        );
    }
}

export default NoAudioPlayer