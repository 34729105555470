import React, { useState, useEffect } from "react";
import {
  BsArrowRepeat,
  BsAspectRatio,
  BsAspectRatioFill,
  BsPauseFill,
  BsPlayFill,
} from "react-icons/bs";

import { FaForward, FaBackward } from "react-icons/fa6";

const convertTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
};

const Controller = ({
  time,
  setTime,
  endTime,
  isPaused,
  setIsPaused,
  setForwardTo,
  startTimestamp,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [rangeValue, setRangeValue] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (time > 1) {
      if (isFullScreen) {
        document.getElementById("root").requestFullscreen();
      } else {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }
      }
    }

    // add event listener for exitFullscreen to change isFullScreen state
    document.addEventListener("fullscreenchange", (event) => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);
      }
    });

    return () => {
      document.removeEventListener("fullscreenchange", () => {});
    };
  }, [isFullScreen]);

  useEffect(() => {
    if (time < 2) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [time]);

  useEffect(() => {
    setRangeValue(time);
  }, [time]);

  const handleRangeLeave = (e) => {
    setIsPaused(true);
    const newTime = e.target.value;
    console.log("newTime", newTime);
    const newTimeInMilliseconds = newTime * 1000;
    const forwardToTimeStamp = startTimestamp + newTimeInMilliseconds;
    console.log("startTimestamp", startTimestamp);
    console.log("forwardToTimeStamp", forwardToTimeStamp);
    setForwardTo(forwardToTimeStamp);
    setTime(+newTime);
    setRangeValue(+newTime);

    // setIsPaused(false);
  };

  const handleRangeChange = (e) => {
    const newTime = e.target.value;
    // console.log("newTime", newTime);
    setRangeValue(+newTime);
  };

  const restart = () => {
    window.location.reload();
  };

  const handleBackward = () => {
    const audioBckBtn = document.querySelector("#audio-bck");
    audioBckBtn.click();
  };

  const handleForward = () => {
    const audioFwdBtn = document.querySelector("#audio-frwd");
    console.log("audioFwdBtn", audioFwdBtn);
    audioFwdBtn.click();
  };
  return (
    <div className="controller">
      <h5>
        {convertTime(time)} / {convertTime(endTime)}
      </h5>
      <button
        className="backward-controller"
        disabled={isDisabled}
        title="Backward"
        onClick={handleBackward}
      >
        <FaBackward />
      </button>
      <button
        className="play-btn"
        title="Play/Pause"
        disabled={isDisabled}
        onClick={() => setIsPaused((i) => !i)}
      >
        {isPaused ? <BsPlayFill /> : <BsPauseFill />}
      </button>
      <button
        className="forward-controller "
        disabled={isDisabled}
        title="Forward"
        onClick={handleForward}
      >
        <FaForward />
      </button>

      <input
        step={2}
        type="range"
        min="0"
        max={endTime}
        value={rangeValue}
        disabled={isDisabled}
        className="slider"
        // onChange={handleRangeChange}
        // onDrag={() => setIsPaused(true)}
        // onDragEnter={() => setIsPaused(true)}
        // onTouchEnd={handleRangeLeave}
        // onMouseUp={handleRangeLeave}
      />

      <button title="Restart" className="restart-btn" onClick={restart}>
        <BsArrowRepeat fontSize="2rem" />
      </button>
      <button
        onClick={() => {
          setIsFullScreen((i) => !i);
        }}
        className="fullscreen-btn"
        title="Fullscreen"
      >
        {isFullScreen ? (
          <BsAspectRatioFill fontSize="2rem" />
        ) : (
          <BsAspectRatio fontSize="2rem" />
        )}
      </button>
    </div>
  );
};

export default Controller;
