import { useState, useEffect } from "react";
import "./App.css";
import AudioPlayer from "./AudioPlayer";
import AWSManagement from "./AWSManagement";
import Player from "./Player";
import Logo from "./ic.png";
import Timer from './Timer';
import Controller from './Controller';
import axios from "axios";
import { generateRecordingData, getRecordingData, saveRecordingData } from "./api/recording_data";
import NoAudioPlayer from "./NoAudioPlayer";

const calculateDealy = (audio1, audio2) => {
  const audio1StartTime = audio1.split("/")[5].split(".")[0];
  const audio2StartTime = audio2.split("/")[5].split(".")[0];
  const delayTimestamp = Math.abs(audio1StartTime - audio2StartTime);
  const delay = Math.round(delayTimestamp / 1000);
  return delay;
}


function findPreviousWithNext(array, targetTimestamp) {
  const targetNumber = parseInt(targetTimestamp, 10);

  let closestTimestamp = null;
  let closestDifference = Infinity;

  for (let i = 0; i < array.length; i++) {
    const currentNumber = parseInt(array[i], 10);
    const difference = currentNumber - targetNumber;
    // Dont allow negative differences
    if (difference < 0) {
      continue;
    }
    const gap = difference / 1000;

    if (difference <= closestDifference && i > 0 && i < array.length - 1) {
      closestDifference = difference;
      closestTimestamp = {
        index: i,
        previousTimestamp: parseInt(array[i], 10),
        nextTimestamp: parseInt(array[i + 1], 10),
        targetNumber,
        gap: gap,
      };
    }
  }
  console.log("closestTimestamp", closestTimestamp);
  // return closestTimestamp;
}


function App() {
  const [finalAudio, setFinalAudio] = useState(null)
  const [audioFiles, setAudioFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [playStarted, setPlayStarted] = useState(false);
  const [time, setTime] = useState(0);
  const [endTime, setEndTime] = useState(0)
  const [isPaused, setIsPaused] = useState(false)
  const [forwardTo, setForwardTo] = useState(0);
  const [startTimestamp, setStartTimestamp] = useState(0);
  const [endTimestamp, setEndTimestamp] = useState(0);
  const [playingForTheFirstTime, setPlayingForTheFirstTime] = useState(true);
  const [hasAudio, setHasAudio] = useState(false);

  useEffect(() => {
    console.log(videoFiles);
    if (videoFiles.length > 0) {
      // Check in noaudio in url then set endTime to the time gap between the first and last video
      const urlParams = new URLSearchParams(window.location.search);

      const noaudio = urlParams.get("noaudio");
      if (noaudio === "1") {
        const vf = filteredVideoFiles(videoFiles);
        const firstVideo = vf[0]["chunks"][0];
        const lastVideo = vf[vf.length - 1]["chunks"][vf[vf.length - 1]["chunks"].length - 1];
        const firstVideoTimestamp = firstVideo.split("/")[2].split(".")[0];
        const lastVideoTimestamp = lastVideo.split("/")[2].split(".")[0];
        const gap = Math.abs(lastVideoTimestamp - firstVideoTimestamp);
        console.log("gap", gap);
        setEndTime(Math.round(gap / 1000));
      }
    }
  }, [audioFiles, videoFiles]);

  useEffect(() => {
    if (finalAudio) {
      const audio = new Audio(finalAudio);
      audio.onloadedmetadata = () => {
        console.log("audio.duration", audio.duration);
        setEndTime(Math.round(audio.duration));
      }
    }

  }, [finalAudio, hasAudio])

  useEffect(() => {
    if (playStarted) {
      const interval = setInterval(() => {
        if (!isPaused) {
          setTime((time) => time + 1);
        }

        if (time > endTime) {
          alert("Finished playing");
          window.location.reload();
        }
        const before20Seconds = endTime - 20;
        if (time === before20Seconds) {
          saveRecordingData()
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [playStarted, isPaused, time]);

  useEffect(() => {
    getD();
  }, []);




  const getD = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get("cid");
    const noaudio = urlParams.get("noaudio");
    setHasAudio(noaudio === "1" ? false : true);
    const pup = urlParams.get("pup");
    if (cid) {
      if (pup) {
        generateRecordingData();
      }
      const res = await getRecordingData(cid);
      if (!res) {
        console.log("Playing for the 1st time No data found for this cid");
        setPlayingForTheFirstTime(true);
      }
      else {
        console.log("Not Playing for the 1st time No data found for this cid");
        setPlayingForTheFirstTime(false);
      }

    }
  }




  const filteredVideoFiles = (videoFiles) => {

    // Filter the video files by removing duplicate id objects

    const filteredVideoFiles = videoFiles.filter((videoFile, index) => {
      return videoFiles.findIndex((video) => video.id === videoFile.id) === index;
    }
    )

    return filteredVideoFiles;
  }


  return (
    <div className="wrapper">
      {/* <Timer time={time} /> */}
      {hasAudio ? <>
        {(videoFiles.length && playStarted) ? (
          <Player
            videoFiles={filteredVideoFiles(videoFiles)}
            time={time}
            isPaused={isPaused}
            playStarted={playStarted}
            forwardTo={forwardTo}
            setForwardTo={setForwardTo}
          />
        ) : (
          <div style={{ width: "1000px" }} className="logo-container">
            <img
              style={{
                width: "700px",
              }}
              src={Logo}
              alt="logo"
            />
          </div>
        )}
      </> :
        <>
          {videoFiles.length ? (<NoAudioPlayer
            videoFiles={filteredVideoFiles(videoFiles)}
            time={time}
            isPaused={isPaused}
            playStarted={playStarted}
            forwardTo={forwardTo}
            setForwardTo={setForwardTo}
            setPlayStarted={setPlayStarted}
          />) : (
            <div style={{ width: "1000px" }} className="logo-container">
              <img
                style={{
                  width: "700px",
                }}
                src={Logo}
                alt="logo"
              />
            </div>)
          }
        </>
      }
      <AWSManagement
        setAudioFiles={setAudioFiles}
        setVideoFiles={setVideoFiles}
        setFinalAudio={setFinalAudio}
        hasAudio={hasAudio}
      />

      {(hasAudio && audioFiles.length > 1) ? (
        <AudioPlayer
          audio1={audioFiles[0]["audio0"]}
          audio2={audioFiles[1]["audio1"]}
          DELAY={2}
          setPlayStarted={setPlayStarted}
          playStarted={playStarted}
          isPaused={isPaused}
          time={time}
          setTime={setTime}
          forwardTo={forwardTo}
          setForwardTo={setForwardTo}
          setIsPaused={setIsPaused}
          startTimestamp={startTimestamp}
          finalAudio={finalAudio}
          playingForTheFirstTime={playingForTheFirstTime}
          hasAudio={hasAudio}
        />
      ) : (
        ""
      )}
      {playStarted ? (<Controller
        time={time}
        setTime={setTime}
        endTime={endTime}
        isPaused={isPaused}
        setIsPaused={setIsPaused}
        setForwardTo={setForwardTo}
        startTimestamp={startTimestamp}

      />) : ""}
    </div>
  );
}

export default App;
